import React, { useMemo } from "react";
import {
  useReactTable,
  getCoreRowModel,
  createColumnHelper,
  getExpandedRowModel,
} from "@tanstack/react-table";
import { Header } from "./components/header/Header";
import { Pagination } from "./components/pagination/Pagination";
import { TableProps } from "./types";
import { UUID } from "api/types";
import { Typography } from "components/miloDesignSystem/atoms/typography";
import { mainListUiSchema } from "./uiSchemas";
import { useInitializeTable } from "./hooks/useInitializeTable/useInitializeTable";
import { BodyWrapper } from "./components/bodyWrapper/BodyWrapper";
import { TABLE_FILL_WIDTH_ID } from "./constants";
import { cx } from "utilities";

export const Table = <T extends { id: UUID | number }>({
  rows,
  isLoading,
  pagination,
  onPaginationChange,
  columns,
  onRowClick,
  overrides,
  error,
  showIndexColumn,
  uiSchema = mainListUiSchema,
}: TableProps<T>) => {
  const defaultData = React.useMemo(() => [], []);
  const paginationState = {
    pageIndex: pagination?.page || 1,
    pageSize: pagination?.pageSize || 30,
  };

  const overwrittenColumns = useMemo(() => {
    const columnHelper = createColumnHelper<T>();
    const localColumns = [...columns];
    if (showIndexColumn) {
      localColumns.unshift(
        columnHelper.display({
          header: "#",
          size: 30,
          cell: props => (
            <Typography fontSize="12" fontWeight="700">
              {props.row.depth
                ? ""
                : `${props.row.index + 1 + (paginationState.pageIndex - 1) * 30}.`}
            </Typography>
          ),
        }),
      );
    }

    localColumns.push(
      columnHelper.display({
        header: "",
        id: TABLE_FILL_WIDTH_ID,
        size: 0,
        cell: props => null,
      }),
    );
    return localColumns;
  }, [columns, showIndexColumn, paginationState.pageIndex]);

  const table = useReactTable({
    data: rows || defaultData,
    columns: overwrittenColumns,
    pageCount: pagination?.count ?? -1,
    //@ts-ignore
    getSubRows: row => row.subRows,
    getExpandedRowModel: getExpandedRowModel(),
    state: {
      pagination: paginationState,
      expanded: true,
    },
    getRowId: row => String(row.id),
    onPaginationChange: updaterOrValue => {
      if (!onPaginationChange) return;

      if (typeof updaterOrValue === "function") {
        onPaginationChange(updaterOrValue(paginationState));
        return;
      }

      onPaginationChange(updaterOrValue);
    },
    defaultColumn: {
      size: 200,
      minSize: 0,
      maxSize: 5000,
    },
    getCoreRowModel: getCoreRowModel(),
    manualPagination: true,
    debugTable: true,
  });

  const { containerRef, isInitializing } = useInitializeTable(table, rows);

  return (
    <div
      className={cx("d-flex flex-column flex-1 overflow-hidden", overrides?.().table?.className)}
      ref={containerRef}
    >
      <div className="overflow-auto d-flex flex-column flex-1 position-relative">
        {!isInitializing && (
          <>
            {overrides?.().hideHeader ? null : (
              <Header
                isLoading={isLoading || isInitializing}
                overrides={overrides}
                showIndexColumn={showIndexColumn}
                table={table}
                uiSchema={uiSchema}
              />
            )}

            <BodyWrapper
              error={error}
              isLoading={isLoading || isInitializing}
              table={table}
              onRowClick={onRowClick}
              overrides={overrides}
              uiSchema={uiSchema}
              columns={columns}
              containerRef={containerRef}
            />
          </>
        )}
      </div>
      {Boolean(onPaginationChange) && Boolean(pagination) && <Pagination table={table} />}
    </div>
  );
};
