import { PickingDetails } from "api/wms/models";
import { InfoLabel } from "components/common/infoLabel";
import { Avatar } from "components/miloDesignSystem/atoms/avatar";
import { Typography } from "components/miloDesignSystem/atoms/typography";
import { RightPanelSection } from "components/utils/drawer";
import styles from "../RightPanel.module.css";
import QRCode from "qrcode.react";
import { useSelector, useStateModal } from "hooks";
import { usePickingPatchMutation, usePickingPatchMutationWithoutParsing } from "api/wms/hooks";
import { Select } from "components/miloDesignSystem/molecules/select";
import { MenuItemType } from "components/miloDesignSystem/atoms/menu/types";
import { PickingFinishDate } from "../../components/PickingFinishDate";
import { PickingStartDate } from "../../components/PickingStartDate";
import { dateFns } from "utilities";
import { getParsedDuration } from "pages/wms/shared/utils/getParsedDuration";
import { DatePicker } from "components/utils/datePicker";
import { TimePicker } from "components/utils/timePicker";
import { PickingDeparture } from "./PickingDeparture";
import {
  PickingWorkspaceModal,
  PickingWorkspaceState,
} from "./pickingWorkspaceModal/PickingWorkspaceModal";
import { EmptyValue } from "utilities/tableColumnsUtilities/createTableColumns/createTableColumns";
import { AsyncInput } from "components/utils";

interface Props {
  picking: PickingDetails;
}

export const GeneralInfoSection = ({ picking }: Props) => {
  const ramps = useSelector(store => store.partials.wmsRamps);
  const rampMutation = usePickingPatchMutationWithoutParsing();
  const estimatedTimeOfPickingDateMutation = usePickingPatchMutation();
  const estimatedTimeOfPickingTimeMutation = usePickingPatchMutation();
  const descriptionMutation = usePickingPatchMutation();
  const pickingWorkspaceModal = useStateModal<PickingWorkspaceState>();

  return (
    <>
      <RightPanelSection padding="px-3 pt-0 pb-2">
        <div className="d-flex align-items-start justify-content-between gap-2">
          <div>
            <InfoLabel title="rampa">
              <div>
                <Select
                  items={ramps.map(ramp => ({
                    text: ramp.name,
                    type: MenuItemType.TEXT,
                    value: ramp.id,
                  }))}
                  onChange={ramp => {
                    const foundRamp = ramps.find(_ramp => _ramp.id === ramp);
                    if (foundRamp) {
                      rampMutation.mutate({
                        id: picking.id,
                        toUpdate: {
                          ramp: {
                            id: foundRamp.id,
                            name: foundRamp.name,
                          },
                        },
                      });
                    }
                  }}
                  selected={picking.ramp?.id ?? ""}
                />
              </div>
            </InfoLabel>
            <InfoLabel title="planowany początek pickingu">
              <div className="d-flex align-items-center gap-2">
                <DatePicker
                  disabled={estimatedTimeOfPickingDateMutation.isLoading}
                  look="common"
                  overwrites={{
                    popup: { className: styles.datePickerPopup },
                    input: { className: styles.datePickerInput },
                    container: { className: styles.datePickerInput },
                  }}
                  value={picking.estimatedTimeOfPickingDate || ""}
                  onChange={date => {
                    if (!date) {
                      return estimatedTimeOfPickingDateMutation.mutate({
                        id: picking.id,
                        toUpdate: {
                          estimatedTimeOfPickingDate: null,
                        },
                      });
                    }
                    return estimatedTimeOfPickingDateMutation.mutate({
                      id: picking.id,
                      toUpdate: {
                        estimatedTimeOfPickingDate: dateFns.format(new Date(date), "yyyy-MM-dd"),
                      },
                    });
                  }}
                />
                <TimePicker
                  disabled={estimatedTimeOfPickingTimeMutation.isLoading}
                  inProgress={estimatedTimeOfPickingTimeMutation.isLoading}
                  look="common"
                  onBlur={time =>
                    estimatedTimeOfPickingTimeMutation.mutate({
                      id: picking.id,
                      toUpdate: { estimatedTimeOfPickingTime: time || null },
                    })
                  }
                  overwrites={{
                    container: { className: styles.formHeight },
                    input: { className: styles.formHeight },
                  }}
                  value={picking.estimatedTimeOfPickingTime || ""}
                />
              </div>
            </InfoLabel>
            <InfoLabel title="opis">
              <div>
                <AsyncInput
                  disabled={descriptionMutation.isLoading}
                  inProgress={descriptionMutation.isLoading}
                  onChange={value =>
                    descriptionMutation.mutate({
                      id: picking.id,
                      toUpdate: { description: value },
                    })
                  }
                  overwrites={{
                    container: { className: styles.input },
                    input: { className: styles.input },
                  }}
                  placeholder="opis"
                  value={picking.description}
                />
              </div>
            </InfoLabel>
            <InfoLabel title="wyjazd">
              <PickingDeparture picking={picking} />
            </InfoLabel>
            <InfoLabel title="załadunek">
              <Typography fontSize="14" fontWeight="600">
                {picking.estimatedTimeOfLoading
                  ? dateFns.format(new Date(picking.estimatedTimeOfLoading), "H: mm")
                  : "---"}
              </Typography>
            </InfoLabel>
            <div className="d-flex align-items-center gap-2 mb-2">
              <div className={styles.timeContainer}>
                <Typography color="neutralBlack48" fontSize="12" fontWeight="600">
                  start:
                </Typography>
                <PickingStartDate fontSize="14" fontWeight="500" picking={picking} />
              </div>
              <div className={styles.timeContainer}>
                <Typography color="neutralBlack48" fontSize="12" fontWeight="600">
                  zakończenie:
                </Typography>
                <PickingFinishDate fontSize="14" fontWeight="500" picking={picking} />
              </div>
              <div className={styles.timeContainer}>
                <Typography color="neutralBlack48" fontSize="12" fontWeight="600">
                  czas pickingu:
                </Typography>
                <Typography fontSize="14" fontWeight="600">
                  {picking.duration && Boolean(picking.duration.length)
                    ? getParsedDuration(picking.duration)
                    : "---"}
                </Typography>
              </div>
            </div>
            <InfoLabel title="kontrahenci">
              <Typography className={styles.suppliers} fontSize="14" fontWeight="700" noWrap>
                {picking.suppliers && Boolean(picking.suppliers.length)
                  ? picking.suppliers.join(", ")
                  : "---"}
              </Typography>
            </InfoLabel>
            <InfoLabel className={styles.collaborators} title="osoby zaangażowane">
              <div className="d-flex flex-column gap-1">
                {picking.collaborators ? (
                  picking.collaborators.map(collaborator => (
                    <div
                      className="d-flex align-items-center gap-1 cursor-pointer"
                      key={collaborator.id}
                      onClick={() =>
                        pickingWorkspaceModal.open({
                          user: collaborator.id,
                          picking: picking.id,
                        })
                      }
                    >
                      <Avatar size="sm" user={collaborator} theme="light" />
                      <Typography className="ml-1" fontSize="12" fontWeight="700" noWrap>
                        {collaborator.firstName} {collaborator.lastName}
                      </Typography>
                    </div>
                  ))
                ) : (
                  <EmptyValue />
                )}
              </div>
            </InfoLabel>
          </div>
          <div className="d-flex flex-column gap-1">
            <div>
              <Typography color="neutralBlack48" fontSize="12" fontWeight="600">
                QR kod przyjęcia
              </Typography>
            </div>
            <QRCode value={picking.qrCode} />
          </div>
        </div>
      </RightPanelSection>
      {pickingWorkspaceModal.isOpen &&
        pickingWorkspaceModal.state?.picking &&
        pickingWorkspaceModal.state.user && (
          <PickingWorkspaceModal
            close={pickingWorkspaceModal.close}
            state={pickingWorkspaceModal.state}
          />
        )}
    </>
  );
};
