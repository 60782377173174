import { createReduxReducer, arrayToDict } from "utilities";
import { getCustomerPartials, getExternalManufacturerPartials, getPartials } from "api/other/calls";
import { ThunkResult } from "./types";
import { resetStore } from "./auth";
import { ExternalManufacturerPartials, Partials, StartingPoint } from "api/other/models";
import { useDispatch } from "react-redux";
import { useSelector, useDidMount } from "hooks";
import { getCategories } from "api/products/calls";
import { Category, LogCategory } from "api/products/models";
import { FLAVOR } from "CONSTANTS";
import { Error } from "apiConnectors/fetchConnector";
import { createAction } from "@reduxjs/toolkit";

export interface PartialsState {
  fetching: boolean;
  pristine: boolean;
  canUseAllProductsWithCustomer: boolean;
  carriers: Partials["carriers"];
  centerPoints: Partials["centerPoints"];
  error: Error | null;
  businessEntities: Partials["businessEntities"];
  counters: Partials["counters"];
  stageColumnSets: Partials["stageColumnSets"];
  countries: Partials["countries"];
  priceLists: Partials["priceLists"];
  salesAccounts: Partials["salesAccounts"];
  banks: Partials["banks"];
  users: Partials["users"];
  standardUsers: Partials["standardUsers"];
  drivers: Partials["drivers"];
  manufacturingSchemas: Partials["manufacturingSchemas"];
  startingPoints: Partials["startingPoints"];
  ledgerAccounts: Partials["ledgerAccounts"];
  employees: Partials["employees"];
  configuration: Partials["configuration"];
  shippingServices: Partials["shippingServices"];
  shippingShippingServices: Partials["shippingShippingServices"];
  statusesIcons: Partials["statusesIcons"];
  sellers: Partials["sellers"];
  productionSettings: Partials["productionSettings"];
  ramps: Partials["ramps"];
  manufacturers: Partials["manufacturers"];
  customers: Partials["customers"];
  cars: Partials["cars"];
  customer: Partials["customer"];
  owner: Partials["owner"] | null;
  stages: Partials["stages"];
  orderStatusesTemp: Partials["orderStatusesTemp"];
  attributesToFilter: Partials["attributesToFilter"];
  finances: Partials["finances"];
  warehouses: Partials["warehouses"];
  exchangeRates: Partials["exchangeRates"];
  productCategories: Category[] | undefined;
  logCategories: LogCategory[] | undefined;
  warehousemen: Partials["warehousemen"];
  wms: Partials["wms"];
  wmsRamps: Partials["wmsRamps"];
  defaultCategoriesToDownloadLabels: { id: number; name: string }[];
  listingsComparisonButton: boolean;
}

export interface ExternalManufacturerPartialsState {
  employees: ExternalManufacturerPartials["employees"];
  error: Error | null;
  fetching: boolean;
  manufacturer: ExternalManufacturerPartials["manufacturer"];
  pristine: boolean;
}

const externalManufacturerInitialState: ExternalManufacturerPartialsState = {
  employees: [],
  manufacturer: {
    fullName: "",
    id: "",
    name: "",
  },
  fetching: false,
  pristine: true,
  error: null,
};

const initialState: PartialsState = {
  shippingShippingServices: [],
  fetching: false,
  pristine: true,
  error: null,
  counters: [],
  centerPoints: [],
  priceLists: [],
  countries: {
    DE: {
      code: "DE",
      currency: "EUR",
      language: { code: "de", name: "niemiecki" },
      name: { default: "Germany", pl: "Niemcy", de: "Deutsch" },
      phonePrefix: "+49",
    },
    PL: {
      code: "PL",
      currency: "PLN",
      language: { code: "pl", name: "polski" },
      name: { default: "Poland", pl: "Polska", de: "Polen" },
      phonePrefix: "+48",
    },
  },
  salesAccounts: [],
  attributesToFilter: [],
  standardUsers: [],
  ledgerAccounts: [],
  users: [],
  stageColumnSets: [],
  drivers: [],
  employees: [],
  canUseAllProductsWithCustomer: false,
  startingPoints: [],
  stages: [],
  shippingServices: [],
  statusesIcons: [],
  manufacturingSchemas: [],
  businessEntities: [],
  sellers: [],
  defaultCategoriesToDownloadLabels: [],
  cars: [],
  warehousemen: [],
  wmsRamps: [],
  banks: [],
  carriers: [],
  configuration: {
    id: null,
    defaultCurrency: "PLN",
    defaultLoadTime: null,
    email: null,
    contactPerson: null,
    contactPersonPhone: null,
    productCategoriesToCount: [],
    breakTime: 10,
    hasSmsService: true,
    allowUploadOrdersToBaselinker: false,
    isWarehouseInUse: false,
    isUsingPartialComplaints: false,
    hasMapCategoriesCountersService: true,
    hasDailyProductionService: true,
    hasInventoryCheck: false,
    hasProductionOrdersService: true,
    qrCode: "",
    customerSalesAccountLabelsInOrderList:
      "CUSTOMER_AND_SALES_ACCOUNT_LABELS_VISIBLE_IN_ORDER_LIST",
    customerSalesAccountLabelsInOrderListB2b:
      "CUSTOMER_AND_SALES_ACCOUNT_LABELS_VISIBLE_IN_ORDER_LIST",
    showOrderStatusOnOrderList: true,
    showSmsDeliveryDateOnOrderList: false,
    showSmsSentOnOrderList: false,
  },
  productionSettings: {
    showCut: false,
    showSewn: false,
    showPrepared: false,
    showSendToManufacturer: false,
    showReceivedFromManufacturer: false,
    showReadyToCollectFromManufacturer: false,
  },
  ramps: [],

  manufacturers: [],
  logCategories: [],
  warehouses: [],
  customers: [],
  customer: null,
  owner: null,
  orderStatusesTemp: [],
  wms: {
    wmsSettings: [],
  },
  exchangeRates: {
    currencies: [],
    exchangeRateSettings: [],
  },
  finances: {
    tradingDocumentSettings: [],
    tradingDocumentReminderSettings: [],
  },
  productCategories: undefined,
  listingsComparisonButton: true,
};

/**
 * ACTIONS
 */
const request = createAction<void>("partials/request");
export const success = createAction<Partials>("partials/success");
export const externalManufacturerSuccess = createAction<ExternalManufacturerPartials>(
  "partials/success",
);
const failure = createAction<Error>("partials/failure");
const toggleDefaultStartingPoint = createAction<{ id: number; isDefault: boolean }>(
  "partials/toggleDefaultStartingPoint",
);
const addNewStartingPoint = createAction<StartingPoint>("partials/addNewStartingPoint");
const updateStartingPoint = createAction<{ id: number; toUpdate: Partial<StartingPoint> }>(
  "partials/updateStartingPoint",
);
const updateConfiguration = createAction<{ toUpdate: Partial<Partials["configuration"]> }>(
  "partials/updateConfiguration",
);
const decrementOrdersCounter = createAction<{ salesAccount: number }>(
  "partials/decrementOrdersCounter",
);
const setOrdersCounter = createAction<{ salesAccountId: number; numOrders: number }[]>(
  "partials/setOrdersCounter",
);
const decrementProductionCounter = createAction<{ salesAccount: number }>(
  "partials/decrementProductionsCounter",
);
const setProductionCounter = createAction<{ salesAccountId: number; numProductionItems: number }[]>(
  "partials/setProductionCounter",
);
const savePartial = createAction<{ type: AsyncPartialKeys; data: Category[] }>(
  "partials/savePartial",
);
const setPristine = createAction<{ isPristine: boolean }>("partials/savePartial");

/**
 * THUNKS
 */
const fetchPartialsThunk = (search: string = ""): ThunkResult<void> => async (
  dispatch,
  getState,
) => {
  const state = getState();
  const { user } = state.auth;
  const { fetching } = state.partials;
  if (!user || fetching) return;
  dispatch(request());
  const getPartialsByFlavor = {
    main: getPartials,
    b2b: getCustomerPartials,
    transport: getCustomerPartials,
    manufacturing: getPartials,
    externalManufacturing: getExternalManufacturerPartials,
  }[FLAVOR];
  const [partials, error] = await getPartialsByFlavor(search);
  if (partials && !isExternalManufacturerPartials(partials) && FLAVOR !== "externalManufacturing") {
    dispatch(success(partials));
  } else if (partials && isExternalManufacturerPartials(partials)) {
    dispatch(externalManufacturerSuccess(partials));
  } else if (error) {
    dispatch(failure(error));
  }
};

const isExternalManufacturerPartials = (
  object: ExternalManufacturerPartials | Partials,
): object is ExternalManufacturerPartials => {
  return "manufacturer" && "employees" in object;
};

export const actions = {
  fetchPartials: fetchPartialsThunk,
  toggleDefaultStartingPoint,
  addNewStartingPoint,
  updateStartingPoint,
  updateConfiguration,
  decrementOrdersCounter,
  decrementProductionCounter,
  setOrdersCounter,
  setProductionCounter,
  setPristine,
};

/**
 * REDUCERS
 */
export const externalManufacturerReducer = createReduxReducer(externalManufacturerInitialState, {
  [request.type]: (state, _action: ReturnType<typeof request>) => {
    state.fetching = true;
  },
  [failure.type]: (state, action: ReturnType<typeof failure>) => {
    state.fetching = false;
    state.error = action.payload;
  },
  [externalManufacturerSuccess.type]: (
    state,
    action: ReturnType<typeof externalManufacturerSuccess>,
  ) => {
    state.fetching = false;
    state.pristine = false;
    Object.assign(state, action.payload);
    state.employees = action.payload.employees;
    state.manufacturer = action.payload.manufacturer;
    state.error = null;
  },
});

export const reducer = createReduxReducer(initialState, {
  [request.type]: (state, _action: ReturnType<typeof request>) => {
    state.fetching = true;
  },
  [failure.type]: (state, action: ReturnType<typeof failure>) => {
    state.fetching = false;
    state.error = action.payload;
  },
  [setPristine.type]: (state, action: ReturnType<typeof setPristine>) => {
    state.pristine = action.payload.isPristine;
  },
  [success.type]: (state, action: ReturnType<typeof success>) => {
    state.fetching = false;
    state.pristine = false;
    Object.assign(state, action.payload);
    state.startingPoints = action.payload.startingPoints;
    state.salesAccounts = action.payload.salesAccounts;
    state.drivers = action.payload.drivers;
    state.users = action.payload.users;
    state.employees = action.payload.employees;
    state.shippingServices = action.payload.shippingServices;
    state.configuration = action.payload.configuration;
    state.productionSettings = action.payload.productionSettings;
    state.manufacturers = action.payload.manufacturers;
    state.customers = action.payload.customers;
    state.counters = action.payload.counters;
    state.customer = action.payload.customer;
    state.owner = action.payload.owner;
    state.cars = action.payload.cars;
    state.error = null;
  },
  [toggleDefaultStartingPoint.type]: (
    state,
    action: ReturnType<typeof toggleDefaultStartingPoint>,
  ) => {
    const startingPoint = state.startingPoints.find(el => el.id === action.payload.id);
    if (startingPoint) {
      // There can be only one default starting point, so we need to
      // disable the previous one.
      if (action.payload.isDefault === true) {
        state.startingPoints.forEach(startingPoint => {
          startingPoint.isDefault = false;
        });
      }
      startingPoint.isDefault = action.payload.isDefault;
    }
  },
  [addNewStartingPoint.type]: (state, action: ReturnType<typeof addNewStartingPoint>) => {
    state.startingPoints.unshift(action.payload);
  },
  [updateStartingPoint.type]: (state, action: ReturnType<typeof updateStartingPoint>) => {
    const startingPoint = state.startingPoints.find(el => el.id === action.payload.id);
    if (startingPoint) {
      Object.assign(startingPoint, action.payload.toUpdate);
    }
  },
  [updateConfiguration.type]: (state, action: ReturnType<typeof updateConfiguration>) => {
    Object.assign(state.configuration, action.payload.toUpdate);
  },
  [decrementOrdersCounter.type]: (state, action: ReturnType<typeof decrementOrdersCounter>) => {
    const counter = state.counters.find(
      counter => counter.salesAccountId === action.payload.salesAccount,
    );
    if (counter) {
      counter.numOrders -= 1;
    }
  },
  [decrementProductionCounter.type]: (
    state,
    action: ReturnType<typeof decrementProductionCounter>,
  ) => {
    const counter = state.counters.find(
      counter => counter.salesAccountId === action.payload.salesAccount,
    );
    if (counter) {
      counter.numProductionItems -= 1;
    }
  },
  [setOrdersCounter.type]: (state, action: ReturnType<typeof setOrdersCounter>) => {
    state.counters.forEach(counter => {
      const countersDict = arrayToDict(action.payload, { name: "salesAccountId" });
      counter.numOrders = countersDict[counter.salesAccountId]?.numOrders || 0;
    });
  },
  [setProductionCounter.type]: (state, action: ReturnType<typeof setProductionCounter>) => {
    state.counters.forEach(counter => {
      const countersDict = arrayToDict(action.payload, { name: "salesAccountId" });
      counter.numProductionItems = countersDict[counter.salesAccountId].numProductionItems;
    });
  },
  [savePartial.type]: (state, action: ReturnType<typeof savePartial>) => {
    state[action.payload.type] = action.payload.data;
  },
  [resetStore.type]: () => initialState,
});

// ASYNC SELECTOR

const asyncPartials = {
  productCategories: "productCategories",
};
type AsyncPartialKeys = keyof typeof asyncPartials;

/**
 * Selector for picking data from the store partials. If there is no data yet, it will fetch it and save in the store.
 */
export const useAsyncPartialSelector = (() => {
  const fetchData = {
    productCategories: { fetch: () => getCategories("?pageSize=999"), empty: [] },
  };
  let isFetching = false;
  let isPristine = true;
  const useAsyncPartialSelector = (
    type: AsyncPartialKeys,
    // selector?: (store: Store) => unknown,
  ) => {
    const dispatch = useDispatch();
    const data = useSelector(store => store.partials[type]);
    useDidMount(() => {
      async function fetchPartial() {
        const [payload] = await fetchData[type].fetch();
        isFetching = false;
        if (payload) {
          dispatch({ type: savePartial.type, payload: { type, data: payload.results } });
        }
      }
      if (isFetching === false && (isPristine === true || data === undefined)) {
        isFetching = true;
        isPristine = false;
        fetchPartial();
      }
    });
    return data === undefined ? fetchData[type].empty : data;
  };
  return useAsyncPartialSelector;
})();
