import { Pagination, UUID } from "api/types";
import { PartialOf } from "typeUtilities";
import {
  BulkSalesInvoiceConfirmPreview,
  LightTradingDocument,
  PatchTradingDocumentItemsVatRatePayload,
  SendEmailNotificationToMultiple,
  TradingDocument,
} from "./models";
import { queryFetch } from "apiConnectors/queryFetch";
import { parsePatchData } from "utilities/parsePatchData";
import { Assign } from "utility-types";
import { ApiFetcher } from "hooks/createApiQuery";
import { tradingDocumentsKeys } from "./keys";

const getLightTradingDocuments = (
  search: string = "",
): ApiFetcher<Pagination<LightTradingDocument>> => ({
  key: tradingDocumentsKeys.lightTradingDocument.list(search),
  fetcher: () =>
    queryFetch({
      method: "GET",
      url: "/finances/trading-documents/light" + search,
    }),
});

const bulkUpdateTradingDocuments = (args: {
  ids: UUID[];
  toUpdate: Assign<Omit<PartialOf<TradingDocument>, "ledgerAccount">, { ledgerAccount?: UUID }>;
}) =>
  queryFetch<{ message: "ok" }>({
    method: "PATCH",
    url: "/finances/trading-documents/items/bulk-update",
    data: {
      ...parsePatchData(args.toUpdate),
      ids: args.ids,
    },
  });

const patchTradingDocumentItemsVatRate = (data: PatchTradingDocumentItemsVatRatePayload) =>
  queryFetch<void>({
    method: "PATCH",
    url: "/finances/trading-documents-items/set-vat-rate",
    data,
  });

const postBulkConfirmSalesInvoice = (data: { tradingDocumentsIds: UUID[] }) =>
  queryFetch<{ message: BulkSalesInvoiceConfirmPreview }>({
    method: "POST",
    url: "/finances/trading-documents/bulk-confirm-sales-invoices",
    data,
  });

const postFiscalizeMultipleReceipts = (data: { tradingDocuments: UUID[] }) =>
  queryFetch<void>({
    method: "POST",
    url: "/finances/trading-documents/print-receipts",
    data,
  });

const postMultipleEmailNotifications = (data: SendEmailNotificationToMultiple) =>
  queryFetch<void>({
    method: "POST",
    url: "/finances/trading-documents/send-to-recipient",
    data,
  });

export const tradingDocumentsApi = {
  bulkUpdateTradingDocuments,
  patchTradingDocumentItemsVatRate,
  postBulkConfirmSalesInvoice,
  postFiscalizeMultipleReceipts,
  getLightTradingDocuments,
  postMultipleEmailNotifications,
};
