import { Courier, PackageType } from "api/shipping/models";
import { InfoLabel } from "components/common/infoLabel";
import { RightPanelSection } from "components/utils/drawer";
import { shippingActions } from "api/shipping/actions";
import { ShippingService } from "constants/shippingService";
import { Select } from "components/miloDesignSystem/molecules/select";
import { MenuItemType } from "components/miloDesignSystem/atoms/menu/types";
import { assertIsDefined } from "utilities/assertIsDefined";
import { Checkbox } from "components/miloDesignSystem/atoms/checkbox";
import { memo } from "react";

interface Props {
  courier: Courier;
}

export const DefaultOptionsSection = (props: Props) => {
  switch (props.courier.provider) {
    case ShippingService.AMBRO: {
      return <Ambro {...props} />;
    }
    case ShippingService.DPD: {
      return null;
    }
    case ShippingService.GLS: {
      return null;
    }
    case ShippingService.MEBEL_TAXI: {
      return null;
    }
    case ShippingService.SPT: {
      return null;
    }
    case ShippingService.ZADBANO: {
      return null;
    }

    default:
      const exhaustiveCheck: never = props.courier.provider;
      throw new Error(`Unhandled courier case: ${exhaustiveCheck}`);
  }
};

const Ambro = ({ courier }: Props) => {
  const ambroDefaultPackageTypeMutation = shippingActions.useCourierPatchMutation();
  const ambroDefaultServiceMutation = shippingActions.useCourierPatchMutation();
  return (
    <RightPanelSection padding="px-3 pt-0 pb-0">
      <InfoLabel title="domyślny typ paczki">
        <Select
          items={Object.values(PackageType).map(option => ({
            value: option,
            text: option,
            type: MenuItemType.TEXT,
          }))}
          onChange={ambroDefaultPackageType => {
            assertIsDefined(ambroDefaultPackageType);
            ambroDefaultPackageTypeMutation.mutate({
              id: courier.id,
              toUpdate: { ambroDefaultPackageType: ambroDefaultPackageType as PackageType },
            });
          }}
          selected={courier.ambroDefaultPackageType}
        />
      </InfoLabel>
      <InfoLabel title="domyślny typ usługi">
        <Select
          items={courier.ambroServicesOptions.map(option => ({
            value: option.id,
            text: option.name,
            type: MenuItemType.TEXT,
          }))}
          onChange={id => {
            const ambroDefaultService = courier.ambroServicesOptions.find(
              service => service.id === id,
            );
            assertIsDefined(ambroDefaultService);
            ambroDefaultServiceMutation.mutate({
              id: courier.id,
              toUpdate: { ambroDefaultService: ambroDefaultService.id },
            });
          }}
          selected={courier.ambroDefaultService}
        />
      </InfoLabel>
      <InfoLabel title="usługi dodatkowe" className="align-items-start mt-1">
        <div className="d-flex flex-column">
          {courier.ambroAdditionalServicesOptions.map(option => (
            <OptionCheckbox key={option.id} option={option} courier={courier} />
          ))}
        </div>
      </InfoLabel>
    </RightPanelSection>
  );
};

const OptionCheckbox = memo(
  ({
    option,
    courier,
  }: {
    option: Courier["ambroAdditionalServicesOptions"][number];
    courier: Courier;
  }) => {
    const ambroDefaultAdditionalServicesMutation = shippingActions.useCourierPatchMutation();
    return (
      <div key={option.id}>
        <Checkbox
          checked={Boolean(
            courier.ambroDefaultAdditionalServices.find(service => service === option.id),
          )}
          label={option.name}
          onChange={status => {
            const ambroDefaultAdditionalServices = status
              ? [...courier.ambroDefaultAdditionalServices, option.id]
              : courier.ambroDefaultAdditionalServices.filter(
                  addedOption => addedOption !== option.id,
                );

            ambroDefaultAdditionalServicesMutation.mutate({
              id: courier.id,
              toUpdate: {
                ambroDefaultAdditionalServices,
              },
            });
          }}
        />
      </div>
    );
  },
);
