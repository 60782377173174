import { AnyAction, CaseReducers, createReducer, Reducer } from "@reduxjs/toolkit";
import { Order, OrderPaymentSource, OrderPaymentType } from "api/orders/models";
import { AxiosError } from "axios";
import {
  add,
  addDays,
  addMinutes,
  differenceInDays,
  eachWeekOfInterval,
  isValid,
  endOfWeek,
  format,
  parse,
  getWeek,
  isToday,
  isYesterday,
  lastDayOfMonth,
  isSunday,
  isSaturday,
  lastDayOfQuarter,
  lastDayOfWeek,
  startOfMonth,
  startOfQuarter,
  addMonths,
  startOfWeek,
  subDays,
  subMinutes,
  subMonths,
  subQuarters,
  intervalToDuration,
  parseISO,
  isPast,
  isTomorrow,
} from "date-fns";
import plLocale from "date-fns/locale/pl";
import {
  array as yupArray,
  boolean as yupBoolean,
  number as yupNumber,
  object as yupObject,
  reach as yupReach,
  ref as yupRef,
  string as yupString,
} from "yup";
import classNames from "classnames";
import { ClassNamesFn } from "classnames/types";
import { TypographyProps } from "components/miloDesignSystem/atoms/typography/types";
import { formatDate } from "./createSingleDateOptions";

interface Pagination {
  next: number | null;
  prev: number | null;
  count: number | null;
  page: number | null;
  limit: number | null;
  pageSize: number | null;
  indexes: number[];
}

interface Options {
  allowEmpty?: boolean;
  forceFirstSign?: string;
}

interface QueryString {
  parse: (link: string) => { [key: string]: string };
  stringify: (
    query: { [key: string]: string | boolean | number | number[] },
    options?: Options,
  ) => string;
  merge: (arg: string[]) => string;
}

interface CreateReducerConfig {
  blockReset?: boolean;
}

interface BackendPagination {
  next?: string | null;
  previous?: string | null;
  count?: number | null;
  limit?: number | null;
  pageSize?: number;
}

export const yup = {
  object: yupObject,
  string: yupString,
  boolean: yupBoolean,
  array: yupArray,
  number: yupNumber,
  reach: yupReach,
  ref: yupRef,
};

export function createReduxReducer<T>(
  initialState: T,
  handlers: CaseReducers<T, any>,
  config: CreateReducerConfig = {},
): Reducer<T, AnyAction> {
  return createReducer(initialState, { ...handlers, RESET_STORE: () => initialState });
}

/**
 * function returns reducer function for redux store
 */
// export function createReducer<T>(
//   initialState: T,
//   handlers: { [key: string]: (arg: T, action: PayloadAction) => T },
//   config: CreateReducerConfig = {},
// ): {} {
//   return function reducer(state: T = initialState, action: PayloadAction) {
//     if (!config.blockReset) {
//       handlers.RESET_STORE = () => initialState;
//     }
//     if (handlers.hasOwnProperty(action.type)) {
//       return handlers[action.type](state, action);
//     } else {
//       return state;
//     }
//   };
// }

/**
 * set of methods for querystring
 */
export const queryString: QueryString = {
  parse: function parseQuery(link: string = "") {
    if (!link || link.length === 0 || link.indexOf("?") === -1) {
      return {};
    }
    const qstr = link.split("?")[1];
    const query: { [key: string]: string } = {};
    const a = qstr.split("&");
    for (var i = 0; i < a.length; i++) {
      var b = a[i].split("=");
      query[decodeURIComponent(b[0])] = decodeURIComponent(b[1] || "");
    }
    return query;
  },
  stringify: function stringifyQuery(query, options = {}) {
    const { allowEmpty = false, forceFirstSign }: Options = options;
    let queryString = "";

    Object.keys(query).forEach(key => {
      const value = query[key];
      let param = "";
      if (queryString.length === 0) {
        param += "?";
      } else {
        param += "&";
      }
      param += key + "=";
      if (Array.isArray(value)) {
        param += value;
      } else {
        param += encodeURIComponent(value);
      }
      if ((value !== undefined && value !== null && value !== "") || allowEmpty === true) {
        queryString += param;
      }
    });

    if (queryString.length > 0 && forceFirstSign) {
      queryString = forceFirstSign + queryString.substring(1);
    }

    return queryString;
  },
  merge: function mergeQueryStrings(queryStrings) {
    let query = {};
    queryStrings.forEach(string => {
      query = { ...query, ...queryString.parse(string) };
    });
    return queryString.stringify(query);
  },
};

/**
 * preparing django pagination object
 */
export function getPagination(pagination: BackendPagination | null): Pagination {
  if (pagination === null) {
    return {
      next: null,
      prev: null,
      count: null,
      limit: null,
      page: null,
      pageSize: null,
      indexes: [],
    };
  }
  const { next, previous, count = null, limit = null, pageSize: backendPageSize } = pagination;
  function getPageSize() {
    return count && limit ? Math.ceil(count / limit / 10) * 10 : null;
  }
  const nextPage = next ? parseInt(queryString.parse(next).page, 10) : null;
  const prevPage = previous ? parseInt(queryString.parse(previous).page, 10) || 1 : null;
  const page = (function() {
    if (nextPage) return nextPage - 1;
    if (prevPage) return prevPage + 1;
    return 1;
  })();
  const pageSize = backendPageSize || getPageSize();
  let indexes = [];

  if (pageSize) {
    let i = 0;
    for (i; i < pageSize; i += 1) {
      indexes.push(pageSize * (page - 1) + i + 1);
    }
  }

  return {
    next: nextPage,
    prev: prevPage,
    count,
    page,
    limit,
    pageSize,
    indexes,
  };
}

/**
 * helper function to handle many fetches at once.
 * Request should be GET type only.
 * Returns error if at least one fetch is failure.
 * Returns isCanceled if at least one fetch is canceled.
 * Status always the biggest, e.g. for 500, 404, 200 status will be 500.
 * Usage:
 * ```js
 * const [[a, b, c], error, { isCanceled, status }] = await handleFetches([
  () => getA(param1),
  () => getB(param2),
  () => getC(param3),
]);
 * ```
 * @param {array} fetches
 */
export async function handleFetches<A, B, C extends { isCanceled: boolean; status: number }>(
  fetches: Fetches<B, C>,
) {
  const promises = fetches.map(request => request());
  const results = await Promise.all(promises);
  const error = (() => {
    const res = results.find(res => res[1]);
    if (res) {
      return res[1];
    } else {
      return null;
    }
  })();
  const isCanceled = (() => {
    const exist = results.find(res => res[2].isCanceled);
    if (exist) {
      return exist[2].isCanceled;
    } else {
      return false;
    }
  })();
  const status = results.map(res => res[2].status).sort((a, b) => (b > a ? 1 : -1))[0];
  const payloads = results.map(res => res[0]);
  const statuses = { isCanceled, status };
  return [payloads, error, statuses] as [A[], typeof error, typeof statuses];
}
type Fetches<B, C> = Array<() => Promise<[any, B, C]>>;

type Narrowable = string | number | boolean | undefined | null | void | {};
export const tuplify = <T extends Narrowable[]>(...t: T) => t;

/**
 * Safely picks attribute from nested object / array.
 * Optional chaining substitute.
 * @param {Object} obj
 * @param {string} attr
 * @param {string=} placeholder
 * @example
 * safePick(error, "attributesValues.0.value")
 */
export function safePick(
  obj: { [key: string]: any } | null,
  attr: string,
  placeholder: string = "",
): string {
  const attributes = attr.split(".");
  let newObj: any = { ...obj };
  attributes.forEach((attribute: any) => {
    if (newObj && newObj[attribute]) {
      newObj = newObj[attribute];
    } else {
      newObj = placeholder;
    }
  });
  return typeof newObj === "string" ? newObj : placeholder;
}

export const dateFns = (function() {
  const dateFnsLocales = {
    pl: plLocale,
    en: undefined,
  };
  return {
    format(date: Date, form: string, params = {}) {
      const locale = dateFnsLocales["pl"];
      return format(date, form, { locale, ...params });
    },
    formatRelative(date: Date, formatDate: string = "dd.MM.yyyy", hideHours: boolean = false) {
      if (!hideHours) {
        if (isToday(date)) return `dzisiaj o ${format(date, "H:mm")}`;
        if (isYesterday(date)) return `wczoraj o ${format(date, "H:mm")}`;
        if (isTomorrow(date)) return `jutro, ${format(date, "H:mm")}`;
      }
      if (hideHours) {
        if (isToday(date)) return "dzisiaj";
        if (isYesterday(date)) return "wczoraj";
        if (isTomorrow(date)) return "jutro";
      }
      return format(date, formatDate);
    },
    add,
    addDays,
    subMinutes,
    getWeek,
    isValid,
    parse,
    addMinutes,
    endOfWeek,
    subDays,
    isSunday,
    isSaturday,
    subMonths,
    addMonths,
    subQuarters,
    startOfMonth,
    lastDayOfMonth,
    startOfWeek,
    isToday,
    lastDayOfWeek,
    startOfQuarter,
    lastDayOfQuarter,
    differenceInDays,
    eachWeekOfInterval,
  };
})();

export function throttle(callback: any, wait: any = 200, immediate = false) {
  let timeout: any = null;
  let initialCall = true;

  return function() {
    const callNow = immediate && initialCall;
    const next = () => {
      // @ts-ignore
      callback.apply(this, arguments);
      timeout = null;
    };

    if (callNow) {
      initialCall = false;
      next();
    }

    if (!timeout) {
      timeout = setTimeout(next, wait);
    }
  };
}

export function secondsToTime(num: number) {
  const totalMinutes = num / 60;
  const hours = Math.floor(totalMinutes / 60);
  const minutes = Number((totalMinutes % 60).toFixed(0));
  return { hours, minutes };
}

/**
 * Helper used to pass as the second parameter of React.memo.
 * Prints props equalities in a readable way.
 */
export const propsEqualityPrinter = (
  prev: { [key: string]: any },
  next: { [key: string]: any },
) => {
  console.table(
    Object.keys(next).map(el => {
      return [el, prev[el] === next[el]];
    }),
  );
  return false;
};

/**
 * Check if a date is valid
 */
export const isValidDate = function(date: Date) {
  // This comparison may seem pointless, but it's not.
  // If the date is invalid, getDate method will return NaN,
  // which returns false when compared to other NaN.
  // If it's valid, it returns integer, which can be safely compared to itself.
  // eslint-disable-next-line no-self-compare
  return date.getDate() === date.getDate();
};

/**
 * Removes duplicates from array
 * @param {array} array
 */
export function removeDuplicates<T extends any>(array: T[]): T[] {
  return [...new Set(array)];
}

/**
 * It takes indexes of array elements and orders them basing on
 * schema. Items which doesn't exist in schema are added at the end, so
 * if x items in, x (or more, if schema has duplicated values) items out.
 * @param {array} arr
 * @param {number[]} schema
 */
export function orderArrayByIndexSchema(arr: any[], schema: number[]) {
  if (!schema.length) return arr;
  let buffer = [...arr];
  const addedIndexes: number[] = [];
  const mapped = schema.map(index => {
    addedIndexes.push(index);
    return buffer[index];
  });
  buffer = buffer.filter((_, index) => !addedIndexes.includes(index));
  return mapped.filter(Boolean).concat(buffer);
}

/**
 * It takes "id" attribute from array elements and orders them basing on
 * schema. Items which don't exist in schema are added at the end, so
 * if x items in, x (or more, if schema has duplicated values) items out.
 * @param {array} arr
 * @param {number[]} schema
 */
export function orderArrayByIdSchema<Element extends { id: number }>(
  arr: Element[],
  schema: number[],
) {
  if (!schema.length) return arr;

  const elementsDict = arr.reduce((acc: { [key: number]: Element }, el) => {
    acc[el.id] = el;
    return acc;
  }, {});

  const addedOrders: { [key: number]: true } = {};
  const mapped = schema.map(id => {
    addedOrders[id] = true;
    return elementsDict[id];
  });
  const schemaOmittedElements: typeof arr = arr.filter(el => !addedOrders.hasOwnProperty(el.id));
  return mapped.filter(Boolean).concat(schemaOmittedElements);
}

export function getAnyErrorKey(
  error: { [key: string]: string } | Record<string, any> | AxiosError | null,
  key: string = "",
  fallback?: string,
) {
  if (!error) return;
  const firstKey = Object.keys(error)[0];
  const normalizedError = error.isAxiosError ? error.response?.data : error;

  return (
    normalizedError[key] ||
    normalizedError.message ||
    normalizedError.detail ||
    normalizedError.details ||
    fallback ||
    normalizedError[firstKey] ||
    "Wystąpił błąd"
  );
}

export function getQueryParam(name: string) {
  return queryString.parse(window.location.search)[name] || "";
}

export const paymentTypeToSourceDict: Record<OrderPaymentType, OrderPaymentSource> = {
  DEBIT_CARD: "ON_DELIVERY",
  CASH_ON_DELIVERY: "ON_DELIVERY",
  ONLINE: "ONLINE",
  INSTALMENT: "ONLINE",
};

export const getPaymentSourceBasedOnPaymentType = (paymentType: Order["payment"]["type"]) =>
  paymentTypeToSourceDict[paymentType];

export function getOrderLink(order: Pick<Order, "status" | "id" | "signature">) {
  function getTab(status: Order["status"]) {
    const statusToTabDict: Record<string, string> = {
      SETTLED: "archive",
      CANCELED: "canceled",
    };

    return statusToTabDict[status] || "active";
  }

  return `/orders/list/${getTab(order.status)}/all?panelId=${order.id}&search=${order.signature}`;
}

/**
 * It's a helper to format api 400 response in a better way that can
 * be used directly to display errors in form
 */

interface BackendErrorDict {
  [key: string]: any;
}

type BackendError = BackendErrorDict | BackendErrorDict[];
export function flattenErrors(error: BackendError) {
  if (!error) return {};

  const errors: { [key: string]: any } = {};
  if (Array.isArray(error)) {
    if (typeof error[0] === "string") {
      errors.message = error[0];
    }
    return errors;
  }

  Object.keys(error).forEach(key => {
    if (typeof error[key] === "string") {
      errors[key] = error[key];
    } else if (error[key] instanceof Array) {
      if (error[key][0] instanceof Object) {
        errors[key] = error[key].map((err: BackendError) => flattenErrors(err));
      } else {
        const [firstKey] = error[key];
        errors[key] = firstKey;
      }
    } else if (error[key] instanceof Object) {
      errors[key] = error[key];
    }
  });

  return errors;
}

/**
 * Helper function
 * @example
 *  multipleArray(["Ala", "Basia"], 3) // ["Ala", "Basia", "Ala", "Basia", "Ala", "Basia"]
 */
export function multiplyArray(arr: any[], num: number) {
  const newArray = [];
  for (let index = 0; index < num; index++) {
    newArray.push(...arr);
  }
  return newArray;
}

export function getDaysFromDurationFilter(filter: string) {
  if (filter.length === 8) return 0;
  return Number(filter.split(" ")[0]);
}

export const replaceDotsWithCommas = (text: string | number | null): string => {
  return String(text).replace(/\./g, ",");
};

export const dateFormatter = (strDate: string): string => {
  // accepts date in different formats, returns date in format dd.mm.yyyy and dateObject
  let dateObj = new Date();
  try {
    dateObj = new Date(strDate);
  } catch {
    dateObj = new Date(parseISO(strDate));
  }
  return format(dateObj, "dd.MM.yyyy");
};

export const closestDayOfWeek = (strDate: string) => {
  // checks if from given date to today is less than 7 days if yes, then adds "najbliższy/a" prefix to day and returns e.g najbliższy piątek

  const closestPrefixWordDictionary: Record<number, string> = {
    0: "najbliższa",
    1: "najbliższy",
    2: "najbliższy",
    3: "najbliższa",
    4: "najbliższy",
    5: "najbliższy",
    6: "najbliższa",
  };
  const dateToday = new Date();
  let closestPrefixWord = "";
  const isDateAfter = (date: Date) => dateToday < date;
  const isDateToday = (date: Date) => dateToday.toDateString() === date.toDateString();
  const dateObj = new Date(strDate);

  const day = dateFns.format(dateObj, "EEEE");

  if (isDateToday(dateObj)) {
    return "(dzisiaj) " + day;
  }

  const timeDiff = intervalToDuration({
    start: dateToday,
    end: dateObj,
  });

  if (timeDiff.days !== undefined && isDateAfter(dateObj)) {
    if (timeDiff.days < 7) {
      closestPrefixWord = closestPrefixWordDictionary[dateObj.getDay()] + " ";
    }
  }

  const dayStr = " (" + closestPrefixWord + day + ")";

  return dayStr;
};

export const cx: ClassNamesFn = (...val) => classNames(...val);

export function capitalizeFirstLetter(text: string) {
  return text.charAt(0).toUpperCase() + text.slice(1);
}

export const parseDescriptionToAttributes = (description: string) => {
  if (description.charAt(description.length - 1) === ";") return description.slice(0, -1);
  return description;
};

export const getWeekColor = (date: string): TypographyProps["color"] => {
  const [year, week] = date.split("-");

  const today = new Date();
  const currentYear = today.getFullYear();
  const currentWeekNumber = Math.ceil(
    ((today.getTime() - new Date(currentYear, 0, 1).getTime()) / 86400000 + 1) / 7,
  );

  if (Number(year) === currentYear && parseInt(week) === currentWeekNumber) return "purple500";
  return "neutralBlack100";
};

export const getMonthColor = (date: string): TypographyProps["color"] => {
  const [year, month] = date.split("-");

  const today = new Date();
  const currentMonth = today.getMonth() + 1;

  if (Number(year) === today.getFullYear() && parseInt(month) === currentMonth) return "purple500";
  return "neutralBlack100";
};

export const getLastWeekOptions = (): { label: string; value: [string, string] }[] => {
  const currentDate = new Date();
  return [
    { label: "Dzisiaj", value: [formatDate(currentDate), formatDate(currentDate)] },
    {
      label: "Wczoraj",
      value: [formatDate(subDays(currentDate, 1)), formatDate(subDays(currentDate, 1))],
    },
    {
      label: "Ostatnie 7 dni",
      value: [formatDate(subDays(currentDate, 7)), formatDate(currentDate)],
    },
  ];
};

export const getIsoDateFormat = (date: string | Date) => {
  return dateFns.format(new Date(date), "yyyy-MM-dd");
};

export const getStandardDateFormat = (date: string | Date) => {
  return dateFns.format(new Date(date), "dd.MM.yyyy");
};

export const getStandardTimeFormat = (date: string | Date) => {
  return dateFns.format(new Date(date), "H:mm");
};

export const handleDateField = (date: string | null): string => {
  if (!date) return "---";
  return getStandardDateFormat(date);
};

export const calculateDaysDifference = (date: Date): number => {
  const today = dateFns.format(new Date(), "yyyy-MM-dd");
  const dateToCheck = dateFns.format(new Date(date), "yyyy-MM-dd");

  if (isPast(new Date(dateToCheck))) {
    return differenceInDays(new Date(dateToCheck), new Date(today));
  } else if (isToday(new Date(dateToCheck))) {
    return 0;
  }
  return differenceInDays(new Date(dateToCheck), new Date(today));
};
