import { useTradingDocument } from "api/trading-documents/hooks";
import { RightPanelHeader } from "components/utils/drawer";
import { useQuery, useStateModal } from "hooks";
import { Button } from "components/common";
import { ConfirmTradingDocument } from "pages/tradingDocuments/shared/components/rightPanel/ConfirmTradingDocument";
import { ConfirmCheckbox } from "pages/tradingDocuments/shared/components/confirmCheckbox/ConfirmCheckbox";
import cx from "classnames";
import { useMutation, useMutationOptions } from "hooks/useMutation";
import { postPreviewCorrection } from "api/trading-documents/calls";
import { getAnyErrorKey } from "utilities";
import { CreateCorrectionManuallyModal } from "./createCorrectionManuallyModal/CreateCorrectionManuallyModal";
import {
  BulkSalesInvoiceConfirmPreview,
  PreviewCorrectionDocument,
} from "api/trading-documents/models";
import { UUID } from "api/types";
import { MoreInfoButton } from "pages/tradingDocuments/shared/components/rightPanel/moreInfoButton/MoreInfoButton";
import darkPlusIcon from "assets/images/darkPlus.svg";
import { IconButton } from "components/miloDesignSystem/atoms/iconButton";
import { Tooltip } from "components/miloDesignSystem/atoms/tooltip";
import { MdiDownloadPdf } from "components/miloDesignSystem/atoms/icons/MdiDownloadPdf";
import { ReplyModal } from "pages/tradingDocuments/shared/components/actionToolbar/ReplyModal";
import { MdiDownloadXml } from "components/miloDesignSystem/atoms/icons/MdiDownloadXml";
import { MdiKeyboardTab } from "components/miloDesignSystem/atoms/icons/MdiKeyboardTab";
import { tradingDocumentsActions } from "api/trading-documents/actions";

interface Props {
  close: () => void;
  isScrolledOver: boolean;
  setShowLoader: React.Dispatch<React.SetStateAction<boolean>>;
}

export const PanelHeader = ({ close, isScrolledOver, setShowLoader }: Props) => {
  const { query } = useQuery();
  const { panelId } = query;
  const { data: salesInvoice } = useTradingDocument({ id: panelId });
  const replyModal = useStateModal<BulkSalesInvoiceConfirmPreview>();
  const handleDownloadOptimaTradeModuleXml = tradingDocumentsActions.useDownloadOptimaTradeModuleXml();
  const handleDownloadOrderXml = tradingDocumentsActions.useDownloadOrderXml();
  const handleSalesInvoicePdfDownload = tradingDocumentsActions.useInvoicePdfDownload();

  const createCorrectionManuallyModal = useStateModal<{
    preview: PreviewCorrectionDocument;
    tradingDocumentId: UUID;
  } | null>();

  const previewCorrectionDocumentOptions = useMutationOptions(
    postPreviewCorrection,
    ({ toastr }) => ({
      onMutate: () => setShowLoader(true),
      onSuccess: payload => {
        setShowLoader(false);
        if (salesInvoice) {
          createCorrectionManuallyModal.open({
            preview: payload,
            tradingDocumentId: salesInvoice.id,
          });
        } else {
          toastr.open({
            type: "warning",
            title: "Wymagane działanie",
            text: "Brak informacji o fakturze",
          });
        }
      },
      onError: error => {
        setShowLoader(false);
        toastr.open({
          type: "warning",
          title: "Wymagane działanie",
          text: getAnyErrorKey(error),
        });
      },
    }),
  );

  const previewCorrectionDocumentMutation = useMutation(
    previewCorrectionDocumentOptions.mutationFn,
    previewCorrectionDocumentOptions,
  );

  if (!salesInvoice) return null;

  return (
    <>
      <RightPanelHeader
        className={cx({
          slidingPanelHeaderShadow: isScrolledOver,
          panelHeaderDuringSlide: isScrolledOver,
        })}
      >
        {isScrolledOver ? (
          <div className="slidingPanelHeader">
            <div className="slidingPanelHeaderContent d-flex align-items-center gap-2">
              <ConfirmCheckbox tradingDocument={salesInvoice} replyModal={replyModal} />
              <div>Faktura VAT&nbsp;{salesInvoice.signature}</div>
            </div>
          </div>
        ) : (
          <ConfirmTradingDocument tradingDocument={salesInvoice} />
        )}

        <div className="d-flex align-items-center justify-content-end gap-1">
          {salesInvoice.status === "CONFIRMED" && (
            <Button
              className="mr-1"
              disabled={previewCorrectionDocumentMutation.isLoading}
              kind="create"
              onClick={() => {
                previewCorrectionDocumentMutation.mutate({
                  tradingDocument: salesInvoice.id,
                });
              }}
              size="small"
            >
              <div className="btnBase btnBaseSmall">
                <img alt="Gotowe" src={darkPlusIcon} style={{ height: "16px", width: "16px" }} />
                <div className="fs-10">Utwórz korektę</div>
              </div>
            </Button>
          )}
          <Tooltip title="Pobierz XML z danymi faktury sprzedażowej">
            <IconButton
              icon={<MdiDownloadXml size="18" />}
              onClick={() => handleDownloadOrderXml(salesInvoice)}
              variant="blackT"
            />
          </Tooltip>
          <Tooltip title="Pobierz XML moduł handel [optima]">
            <IconButton
              icon={<MdiDownloadXml size="18" />}
              onClick={() => handleDownloadOptimaTradeModuleXml([salesInvoice.id])}
              variant="blackT"
            />
          </Tooltip>
          <Tooltip title="Pobierz PDF z danymi faktury sprzedażowej">
            <IconButton
              icon={<MdiDownloadPdf size="18" />}
              onClick={() => handleSalesInvoicePdfDownload(salesInvoice)}
              variant="blackT"
            />
          </Tooltip>
          <MoreInfoButton close={close} invoice={salesInvoice} panelId={panelId} />
          <span className="divider line-divider" />
          <IconButton icon={MdiKeyboardTab} onClick={close} variant="blackT" />
        </div>
      </RightPanelHeader>
      <CreateCorrectionManuallyModal
        close={createCorrectionManuallyModal.close}
        isOpen={createCorrectionManuallyModal.isOpen}
        state={createCorrectionManuallyModal.state}
      />
      {replyModal.isOpen && (
        <ReplyModal bulkInvoiceConfirmation={replyModal.state} close={replyModal.close} />
      )}
    </>
  );
};
