import { useCellFillingStatus } from "api/warehouse/hooks";
import { Typography } from "components/miloDesignSystem/atoms/typography";
import { Spinner } from "components/miloDesignSystem/atoms/spinner";
import { CommonError } from "components/utils";

export const WarehouseFillingStatus = () => {
  const { data, isLoading, error } = useCellFillingStatus({});

  if (error) {
    return (
      <div>
        <CommonError size="small" status={error._httpStatus_} />
      </div>
    );
  }
  return (
    <div className="d-flex align-items-baseline gap-12px">
      <div className="d-flex align-items-baseline gap-1">
        <Typography fontSize="12" fontWeight="700" color="neutralBlack48">
          liczba miejsc:
        </Typography>
        {isLoading ? (
          <Spinner size={16} />
        ) : (
          <Typography fontSize="14" fontWeight="700">
            {data?.totalLocations}
          </Typography>
        )}
      </div>
      <div className="d-flex align-items-baseline gap-1">
        <Typography fontSize="12" fontWeight="700" color="neutralBlack48">
          liczba m. zapełnionych:
        </Typography>
        {isLoading ? (
          <Spinner size={16} />
        ) : (
          <Typography fontSize="14" fontWeight="700">
            {data?.filledLocations}
          </Typography>
        )}
      </div>
      <div className="d-flex align-items-baseline gap-1">
        <Typography fontSize="12" fontWeight="700" color="neutralBlack48">
          liczba m. wolnych:
        </Typography>
        {isLoading ? (
          <Spinner size={16} />
        ) : (
          <Typography fontSize="14" fontWeight="700">
            {data?.freeLocations}
          </Typography>
        )}
      </div>
      <div className="d-flex align-items-baseline gap-1">
        <Typography fontSize="12" fontWeight="700">
          zapełnienie magazynu:
        </Typography>
        {isLoading ? (
          <Spinner size={16} />
        ) : (
          <Typography fontSize="18" fontWeight="700">
            {getFilledLocationPercentage(data?.filledLocationsPercentage)}
          </Typography>
        )}
      </div>
    </div>
  );
};

const getFilledLocationPercentage = (filledLocationsPercentage: number | undefined) => {
  if (filledLocationsPercentage === undefined) return "---";
  if (filledLocationsPercentage > 0 && filledLocationsPercentage < 1) return "<1%";
  return `${filledLocationsPercentage}%`;
};
