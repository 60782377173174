import { PageHeader } from "components/common";
import { PageWrapper } from "components/common/pageWrapper/PageWrapper";
import darkPlusIcon from "assets/images/darkPlus.svg";
import { Table } from "components/miloDesignSystem/molecules/table";
import { mainListUiSchema } from "components/miloDesignSystem/molecules/table/uiSchemas";
import { useQuery, useToggle } from "hooks";
import { queryString } from "utilities";
import { useShippingCouriersColumns } from "./useShippingCouriersColumns";
import { shippingActions } from "api/shipping/actions";
import { Courier } from "api/shipping/models";
import { CreateCourierModal } from "./addCourierModal/CreateCourierModal";
import {
  DrawerHelpers,
  getTablePropsBasedOnDrawerHelpers,
} from "components/utils/drawerHelpers/DrawerHelpers";
import { RightPanel } from "./rightPanel/RightPanel";

export const ShippingCouriers = () => {
  const { query, updateQuery } = useQuery({ exclude: ["panelId"] });
  const search = queryString.stringify(query);

  const { data: couriers, error, isLoading, pagination } = shippingActions.useShippingCouriers(
    search,
  );

  const columns = useShippingCouriersColumns();
  const addCourierModal = useToggle();

  return (
    <>
      <PageWrapper>
        <PageHeader
          searchInput={{
            label: "Szukaj...",
            tags: [],
          }}
          createButton={{
            alt: "utwórz",
            img: darkPlusIcon,
            label: "Utwórz kuriera",
            onClick: addCourierModal.open,
          }}
          viewLabel="SHIPPING_COURIERS"
        />
        <div className="d-flex flex-1 overflow-hidden justify-content-between position-relative">
          <DrawerHelpers name="shippingCourier">
            {drawerHelpers => (
              <>
                <Table<Courier>
                  rows={couriers}
                  columns={columns}
                  onPaginationChange={paginationState => {
                    updateQuery({ ...query, page: paginationState.pageIndex });
                  }}
                  isLoading={isLoading}
                  error={error}
                  pagination={pagination}
                  uiSchema={mainListUiSchema}
                  {...getTablePropsBasedOnDrawerHelpers(drawerHelpers)}
                />

                <RightPanel />
              </>
            )}
          </DrawerHelpers>
        </div>
      </PageWrapper>
      {addCourierModal.isOpen && <CreateCourierModal close={addCourierModal.close} />}
    </>
  );
};
