import { useMutation } from "hooks/useMutation";
import { getAnyErrorKey } from "utilities";
import { wmsUnloadingApi } from "./api";

const usePostStartUnloading = () => {
  return useMutation(wmsUnloadingApi.postStartUnloading, ({ toastr, queryClient }) => ({
    onSuccess: () => queryClient.invalidateQueries(),
    onError: error => {
      toastr.open({
        type: "warning",
        title: "Wymagane działanie",
        text: getAnyErrorKey(error),
      });
    },
  }));
};

const usePostFinishUnloading = () => {
  return useMutation(wmsUnloadingApi.postFinishUnloading, ({ toastr, queryClient }) => ({
    onSuccess: () => queryClient.invalidateQueries(),
    onError: error => {
      toastr.open({
        type: "warning",
        title: "Wymagane działanie",
        text: getAnyErrorKey(error),
      });
    },
  }));
};

export const wmsUnloadingActions = { usePostFinishUnloading, usePostStartUnloading };
