import { createPaginatedApiQuery } from "hooks/createPaginatedQuery";
import { shippingApi, shippingFileFactory } from "./calls";
import { useMutation } from "hooks/useMutation";
import { getAnyErrorKey, pluralize, queryString } from "utilities";
import { Courier, CreateCourier, Shipment, ShipmentListItem, ShippingPiece } from "./models";
import { FormikHelpers } from "formik";
import { shippingKeys } from "./keys";
import { createApiQuery } from "hooks/createApiQuery";
import { parsePatchData } from "utilities/parsePatchData";
import { PartialOf } from "typeUtilities";
import { assertIsDefined } from "utilities/assertIsDefined";
import { Pagination, UUID } from "api/types";
import produce from "immer";
import { useDownloadFeedbackToastr } from "components/utils/downloadFeedback/DownloadFeedbackController";
import { useRedux, useToastr } from "hooks";
import { fileDownloader } from "fileDownloader";
import { ShippingService } from "constants/shippingService";

const useShippingCouriers = createPaginatedApiQuery(shippingApi.getShippingCouriers);
const useShippingCourier = createApiQuery(shippingApi.getShippingCourier);
const useSendToExternal = createApiQuery(shippingApi.getSendShipmentsToExternal);

const useShippingShipments = createPaginatedApiQuery(shippingApi.getShippingShipments);
const useShippingShipment = createApiQuery(shippingApi.getShippingShipment);
const useShippingPieces = createPaginatedApiQuery(shippingApi.getShippingPieces);

const usePostCourier = (close: () => void) => {
  const [dispatch, { partials }] = useRedux();
  const refetchPartials = () => dispatch(partials.fetchPartials());

  const createCourier = useMutation(shippingApi.postShippingCourier, ({ toastr, queryClient }) => ({
    onSuccess: () => {
      queryClient.invalidateQueries(shippingKeys.couriers.list());
      refetchPartials();
      close();
      toastr.open({
        type: "success",
        title: "Udało się!",
        text: "Dodano kuriera",
      });
    },
    onError: error => {
      toastr.open({
        type: "warning",
        title: "Wymagane działanie",
        text: getAnyErrorKey(error),
      });
    },
  }));

  const handleSubmit = (values: CreateCourier, actions: FormikHelpers<CreateCourier>) => {
    createCourier.mutate(values, {
      onSuccess: () => {
        actions.setSubmitting(false);
      },
      onError: error => {
        actions.setSubmitting(false);
        actions.setErrors(error.response?.data);
      },
    });
  };

  return handleSubmit;
};

const useDeleteShippingShipment = () => {
  return useMutation(shippingApi.deleteShippingShipment, ({ toastr, queryClient }) => ({
    onSuccess: payload => {
      queryClient.invalidateQueries();
    },
    onError: error => {
      toastr.open({
        type: "warning",
        title: "Wymagane działanie",
        text: getAnyErrorKey(error),
      });
    },
  }));
};
const usePostShippingPiece = () => {
  return useMutation(shippingApi.postShippingPiece, ({ toastr, queryClient }) => ({
    onSuccess: payload => {
      queryClient.invalidateQueries(shippingKeys.pieces());
    },
    onError: error => {
      toastr.open({
        type: "warning",
        title: "Wymagane działanie",
        text: getAnyErrorKey(error),
      });
    },
  }));
};

export const useCourierPatchMutation = () => {
  return useMutation(
    ({ id, toUpdate }: { id: number; toUpdate: PartialOf<Courier> }) => {
      return shippingApi.patchShippingCourier(parsePatchData(toUpdate), id);
    },
    ({ queryUtils }) => ({
      onMutate: ({ id, toUpdate }) => {
        const prevPanel = queryUtils.handleMutate(shippingKeys.couriers.details(id), toUpdate);
        const prevList = queryUtils.handlePaginatedListUpdate(
          shippingKeys.couriers.list(),
          id,
          toUpdate,
        );
        return { prevList, prevPanel };
      },
      onError: (error, { id }, onMutateReturn) => {
        assertIsDefined(onMutateReturn);
        queryUtils.rollback(shippingKeys.couriers.details(id), onMutateReturn.prevPanel, error);
        queryUtils.rollbackList(shippingKeys.couriers.list(), onMutateReturn.prevList, id);
      },
    }),
  );
};

const usePatchShipping = () => {
  return useMutation(
    ({ id, toUpdate }: { id: UUID; toUpdate: PartialOf<Shipment> }) => {
      return shippingApi.patchShippingShipment(parsePatchData(toUpdate), id);
    },
    ({ queryUtils }) => ({
      onMutate: ({ id, toUpdate }) => {
        const prevPanel = queryUtils.handleMutate(shippingKeys.shipments.details(id), toUpdate);
        const prevList = queryUtils.handlePaginatedListUpdate(
          shippingKeys.shipments.list(),
          id,
          toUpdate,
        );
        return { prevList, prevPanel };
      },
      onError: (error, { id }, onMutateReturn) => {
        assertIsDefined(onMutateReturn);
        queryUtils.rollback(shippingKeys.shipments.details(id), onMutateReturn.prevPanel, error);
        queryUtils.rollbackList(shippingKeys.shipments.list(), onMutateReturn.prevList, id);
      },
    }),
  );
};

const useDeleteShippingPiece = () => {
  return useMutation(shippingApi.deleteShippingPiece, ({ queryClient }) => ({
    onSuccess: () => {
      queryClient.invalidateQueries();
    },
  }));
};

const usePatchShippingPiece = () => {
  return useMutation(
    ({ id, toUpdate }: { id: UUID; toUpdate: PartialOf<ShippingPiece> }) => {
      return shippingApi.patchShippingPiece(parsePatchData(toUpdate), id);
    },
    ({ queryUtils }) => ({
      onMutate: ({ id, toUpdate }) => {
        const prevList = queryUtils.handlePaginatedListUpdate(shippingKeys.pieces(), id, toUpdate);
        return { prevList };
      },
      onError: (error, { id }, onMutateReturn) => {
        assertIsDefined(onMutateReturn);
        queryUtils.rollbackList(shippingKeys.pieces(), onMutateReturn.prevList, id, error);
      },
    }),
  );
};

const usePatchMultipleShippingPieces = () => {
  return useMutation(shippingApi.patchMultipleShippingPieces, ({ queryUtils, queryClient }) => ({
    onMutate: ({ isIncludedForSend, product, shipment }) => {
      const prevList = queryClient.getQueryData(
        shippingKeys.pieces(queryString.stringify({ shipment })),
      );
      queryClient.setQueryData<Pagination<ShippingPiece>>(
        shippingKeys.pieces(queryString.stringify({ shipment })),
        prev => {
          assertIsDefined(prev);
          return produce(prev, draft => {
            const piecesToUpdate = draft.results.filter(piece => piece.productId === product);

            piecesToUpdate.forEach(piece => {
              Object.assign(piece, { isIncludedForSend });
            });
          });
        },
      );
      return { prevList };
    },
    onError: (error, { shipment }, onMutateReturn) => {
      assertIsDefined(onMutateReturn);
      queryUtils.rollback(
        shippingKeys.pieces(queryString.stringify({ shipment })),
        onMutateReturn.prevList,
        error,
      );
    },
  }));
};

const useSendShipmentsToExternal = () => {
  return useMutation(shippingApi.sendShipmentsToExternal, ({ toastr, queryClient }) => ({
    onSuccess: () => {
      queryClient.invalidateQueries();
    },
    onError: (error, { shipments }) => {
      toastr.open({
        type: error.response?.status === 500 ? "failure" : "warning",
        title: `Nie udało się zlecić ${pluralize.pl(shipments.length, {
          singular: "przesyłki",
          plural: "przesyłek",
          other: "przesyłek",
        })}`,
        text: getAnyErrorKey(error),
      });
    },
  }));
};

const useCancelShipments = () => {
  return useMutation(shippingApi.postCancelShipments, ({ toastr, queryClient, queryUtils }) => ({
    onSuccess: () => queryClient.invalidateQueries(),
    onError: (error, { shipments }) => {
      toastr.open({
        type: error.response?.status === 500 ? "failure" : "warning",
        title: `Nie udało się anulować ${pluralize.pl(shipments.length, {
          singular: "przesyłki",
          plural: "przesyłek",
          other: "przesyłek",
        })}`,
        text: getAnyErrorKey(error),
      });
    },
  }));
};

const useDownloadShippingLabel = () => {
  const downloadFeedbackToastr = useDownloadFeedbackToastr();
  const toastr = useToastr();
  const handleDownloadLabel = async (shipmentId: UUID, shipmentServiceId: number) => {
    const downloadToastr = downloadFeedbackToastr.open({ type: "pdf" });
    const { url, name } = shippingFileFactory.shippingLabel(shipmentId, shipmentServiceId);

    const response = await fileDownloader({
      onProgress: downloadToastr.updateProgress,
      url,
      name,
      type: "pdf",
    });
    if (response.status === "success") {
      downloadToastr.lazyClose();
    } else {
      downloadToastr.close();
      toastr.open({
        type: response.httpStatus === 400 ? "warning" : "failure",
        title: response.httpStatus === 400 ? "Wymagane działanie" : "Oj, coś nie tak.",
        text: getAnyErrorKey(response.error),
      });
    }
  };

  return async (row: ShipmentListItem) => {
    if (!row.shippingService) return;
    if (row.shippingService?.provider === ShippingService.MEBEL_TAXI) {
      const data = await shippingApi.getMebelTaxiLabelUrl(row.id, row.shippingService!.id);
      window.open(data.url, "_blank");
      return;
    }
    handleDownloadLabel(row.id, row.shippingService.id);
  };
};

const useDownloadShippingLabels = () => {
  const downloadFeedbackToastr = useDownloadFeedbackToastr();
  const toastr = useToastr();
  const handleDownloadLabels = async (shipments: UUID[]) => {
    const downloadToastr = downloadFeedbackToastr.open({ type: "pdf" });
    const { url, name } = shippingFileFactory.shippingLabels(shipments);

    const response = await fileDownloader({
      onProgress: downloadToastr.updateProgress,
      url,
      name,
    });

    if (response.status === "success") {
      downloadToastr.lazyClose();
    } else {
      downloadToastr.close();
      toastr.open({
        type: response.httpStatus === 400 ? "warning" : "failure",
        title: response.httpStatus === 400 ? "Wymagane działanie" : "Oj, coś nie tak.",
        text: getAnyErrorKey(response.error),
      });
    }
  };

  return async (shipments: UUID[]) => {
    handleDownloadLabels(shipments);
  };
};

const usePatchShippingShipments = () =>
  useMutation(shippingApi.patchShippingShipments, ({ queryClient, toastr }) => ({
    onSuccess: () => {
      queryClient.invalidateQueries();
    },
    onError: error => {
      toastr.open({
        type: "warning",
        title: "Wymagane działanie",
        text: getAnyErrorKey(error),
      });
    },
  }));

const usePostInternalShipment = () =>
  useMutation(shippingApi.postInternalShipment, ({ queryClient, toastr }) => ({
    onSuccess: () => {
      queryClient.invalidateQueries();
    },
    onError: error => {
      toastr.open({
        type: "warning",
        title: "Wymagane działanie",
        text: getAnyErrorKey(error),
      });
    },
  }));

const usePostShippingAuthorize = () =>
  useMutation(shippingApi.postShippingAuthorize, ({ queryClient, toastr }) => ({
    onSuccess: () => queryClient.invalidateQueries(),
    onError: error => {
      toastr.open({
        type: "warning",
        title: "Wymagane działanie",
        text: getAnyErrorKey(error),
      });
    },
  }));

const usePostShippingCheckConnection = () =>
  useMutation(shippingApi.getShippingCheckConnection, ({ toastr, queryClient }) => ({
    onSuccess: () => {
      queryClient.invalidateQueries();

      toastr.open({
        type: "success",
        title: "Połączenie z kurierem jest aktywne",
        text: "",
      });
    },
    onError: error => {
      toastr.open({
        type: "warning",
        title: "Brak połączenia",
        text: getAnyErrorKey(error),
      });
    },
  }));

export const shippingActions = {
  useShippingShipments,
  useShippingCouriers,
  useShippingCourier,
  usePostCourier,
  useCourierPatchMutation,
  useShippingShipment,
  usePatchShipping,
  useShippingPieces,
  usePatchMultipleShippingPieces,
  usePatchShippingPiece,
  useSendShipmentsToExternal,
  useDownloadShippingLabel,
  usePostShippingPiece,
  usePatchShippingShipments,
  useSendToExternal,
  useDeleteShippingPiece,
  useDownloadShippingLabels,
  useCancelShipments,
  usePostInternalShipment,
  usePostShippingAuthorize,
  usePostShippingCheckConnection,
  useDeleteShippingShipment,
};
