import { shippingActions } from "api/shipping/actions";
import { ShipmentListItem, ShipmentStatus as ShipmentStatusChoices } from "api/shipping/models";
import { ColorPalette } from "components/miloDesignSystem/atoms/colorsPalette";
import { IconButton } from "components/miloDesignSystem/atoms/iconButton";
import { MdiQrCode } from "components/miloDesignSystem/atoms/icons/MdiQrCode";
import { MdiPoint } from "components/miloDesignSystem/atoms/icons/mdiPoint/MdiPoint";
import { ProgressBar } from "components/miloDesignSystem/atoms/progressBar";
import { Tag, TagProps } from "components/miloDesignSystem/atoms/tag";
import { Typography } from "components/miloDesignSystem/atoms/typography";
import { AmountDisplay } from "components/miloDesignSystem/molecules/amountDisplay";
import { ShippingService, shippingServiceConstants } from "constants/shippingService";
import { dateFns, getStandardDateFormat, pluralize } from "utilities";
import {
  EmptyValue,
  useCreateTableColumns,
} from "utilities/tableColumnsUtilities/createTableColumns/createTableColumns";
import styles from "./ShippingShipments.module.css";
import { ShipmentStatus } from "./components/columns/ShipmentStatus";
import { countryToFlagDict } from "constants/countriesFlags";
import { CountryCode } from "CONSTANTS";

export const useShippingShipmentsColumns = () => {
  const downloadLabel = shippingActions.useDownloadShippingLabel();

  return useCreateTableColumns<ShipmentListItem>(({ columnHelper }) => {
    return [
      columnHelper.text(row => row.order.signature, {
        header: "sygnatura",
        size: 154,
        typographyProps: {
          fontSize: "16",
        },
      }),
      columnHelper.text(row => row.trackingNumbers.join(", "), {
        header: "nr przesyłki",
        size: 140,
        typographyProps: {
          fontSize: "14",
          fontWeight: "600",
        },
      }),
      columnHelper.accessor(row => row.shippingService, {
        id: "shippingIcon",
        header: "",
        size: 60,
        cell: info => {
          const shippingService: ShipmentListItem["shippingService"] = info.getValue();
          if (!shippingService) return "";
          return (
            <div className={styles.courierLogoWrapper}>
              <img
                className={styles.courierLogo}
                alt="Kurier"
                src={shippingServiceConstants[shippingService.provider as ShippingService].logo}
              />
            </div>
          );
        },
      }),
      columnHelper.text(row => row.shippingService?.name, {
        header: "konto",
        size: 90,
        typographyProps: { fontSize: "14" },
      }),
      columnHelper.accessor(row => row.countryCode, {
        id: "countryCode",
        header: () => (
          <Typography fontSize="12" fontWeight="400" className="text-center" color="neutralBlack48">
            kraj
          </Typography>
        ),
        size: 50,
        cell: info => {
          const countryCode: CountryCode = info.getValue();
          if (!countryCode) return <EmptyValue />;
          return (
            <div className="d-flex justify-content-center align-items-center flex-1 gap-1">
              <img alt="Flaga kraju" src={countryToFlagDict[countryCode]} />
              <Typography fontSize="10" fontWeight="700">
                {countryCode}
              </Typography>
            </div>
          );
        },
      }),
      columnHelper.accessor(row => row, {
        id: "cashOnDelivery",
        header: () => (
          <Typography
            fontSize="12"
            fontWeight="400"
            className="text-right mr-2"
            color="neutralBlack48"
          >
            pobranie
          </Typography>
        ),
        size: 100,
        cell: info => {
          const row = info.getValue();
          return (
            <AmountDisplay
              amount={row.cashOnDelivery}
              currency={row.currency}
              className="justify-content-end flex-1"
            />
          );
        },
      }),
      columnHelper.accessor(row => row.externalStatus, {
        header: "status u kuriera",
        size: 170,
        cell: info => {
          const status: string = info.getValue();
          if (!status) return <EmptyValue />;
          return <Tag label={status} variant="info" type="outlined" />;
        },
      }),
      columnHelper.text(
        row => {
          if (row.lastStatusUpdate) {
            return dateFns.format(new Date(row.lastStatusUpdate), "dd.MM.yyyy, HH:mm");
          }
        },
        {
          header: "w statusie od:",
          size: 108,
          typographyProps: {
            fontWeight: "600",
          },
        },
      ),
      columnHelper.accessor(row => row, {
        header: "status",
        size: 275,
        cell: info => {
          const row = info.getValue() as ShipmentListItem;

          return <ShipmentStatus shipment={row} />;
        },
      }),
      columnHelper.accessor(row => row.externalProgress, {
        header: "progres",
        size: 140,
        cell: info => {
          const progress: number = info.getValue();
          return (
            <div className="d-flex align-items-center flex-1 gap-2">
              <ProgressBar progress={progress} />
              <Typography fontSize="12" fontWeight="700">
                {progress}%
              </Typography>
            </div>
          );
        },
      }),
      columnHelper.accessor(row => row, {
        header: "dostarczyć do:",
        size: 160,
        cell: info => {
          const row = info.getValue() as ShipmentListItem;
          if (!row.shipmentDeadline) return <EmptyValue />;
          const differenceInDays = dateFns.differenceInDays(
            new Date(row.shipmentDeadline),
            new Date(),
          );
          const textColor = getShipmentDeadlineTextColor(differenceInDays);

          const displayDate = (() => {
            if (row.courierDeliveredDate) return "";
            if (differenceInDays === 0) {
              return "dzisiaj";
            }

            if (differenceInDays > 0) {
              return `za ${differenceInDays} ${pluralize.pl(differenceInDays, {
                singular: "dzień",
                plural: "dni",
                other: "dni",
              })}`;
            }
            if (differenceInDays < 0) {
              return `${-differenceInDays} ${pluralize.pl(-differenceInDays, {
                singular: "dzień",
                plural: "dni",
                other: "dni",
              })} temu`;
            }
          })();

          return (
            <div className="d-flex gap-2 align-items-baseline">
              <Typography fontSize="14" fontWeight="700" color={textColor}>
                {getStandardDateFormat(row.shipmentDeadline)}
              </Typography>
              <Typography fontSize="12" fontWeight="600" color={textColor}>
                {displayDate}
              </Typography>
            </div>
          );
        },
      }),
      columnHelper.text(
        row => {
          return row.calledPickupAt && getStandardDateFormat(row.calledPickupAt);
        },
        {
          header: "data nadania",
          size: 90,
        },
      ),
      columnHelper.accessor(row => row, {
        header: "etykieta",
        size: 50,
        cell: info => {
          const row: ShipmentListItem = info.getValue();
          return (
            <div>
              <IconButton
                icon={<MdiQrCode size="16" />}
                disabled={!row.shippingService || !row.trackingNumbers.length}
                onClick={async event => {
                  event.stopPropagation();
                  await downloadLabel(row);
                }}
                variant="blackST"
              />
            </div>
          );
        },
      }),
    ];
  });
};

export const shipmentStatusToTagDict: Record<ShipmentStatusChoices, TagProps<string>> = {
  CALLED_PICKUP_FAILED: { label: "Zarejestrowano u kuriera", variant: "warning", type: "filled" },
  CALLED_PICKUP_SUCCESS: { label: "Zamówiono kuriera", variant: "success", type: "filled" },
  CREATE_SHIPMENT_FAILED: { label: "Błąd podczas rejestracji", variant: "danger", type: "filled" },
  CREATE_SHIPMENT_SUCCESS: {
    label: "Zarejestrowano u kuriera",
    variant: "success",
    type: "filled",
  },
  REGISTRATION_IN_PROGRESS: {
    label: "W trakcie rejestracji",
    startIcon: MdiPoint,
    variant: "deepPurple50",
    type: "filled",
  },
  NO_REGISTERED: { label: "Nie zarejestrowano u kuriera", variant: "quaternary", type: "outlined" },
  CANCELLATION_IN_PROGRESS: {
    label: "W trakcie anulowania",
    startIcon: MdiPoint,
    variant: "deepPurple50",
    type: "filled",
  },
  CANCELED_SHIPMENT_SUCCESS: {
    label: "Anulowano nadanie",
    variant: "warning",
    type: "filled",
  },
  CANCELED_SHIPMENT_FAILED: {
    label: "Błąd podczas anulowania",
    variant: "danger",
    type: "filled",
  },
};

const getShipmentDeadlineTextColor = (differenceInDays: number): ColorPalette => {
  if (differenceInDays <= 5 && differenceInDays > 2) {
    return "warning500";
  }
  if (differenceInDays <= 2) {
    return "danger500";
  }
  return "neutralBlack88";
};
