import { getTabs } from "pages/tradingDocuments/shared/utils/getTabs";
import { proformas } from "components/common/moduleNavigation/moduleConfig/finances/routes/proformas";
import { IconButton } from "components/miloDesignSystem/atoms/iconButton";
import { MdiUploadFile } from "components/miloDesignSystem/atoms/icons/MdiUploadFile";
import { MdiDownloadFile } from "components/miloDesignSystem/atoms/icons/MdiDownloadFile";
import { MdiVisibilityOff } from "components/miloDesignSystem/atoms/icons/MdiVisibilityOff";
import { MdiAreaChart } from "components/miloDesignSystem/atoms/icons/MdiAreaChart";
import { PageHeader } from "components/common";

interface Props {
  additionalListParams: {
    [x: string]: string;
  };
}

export const Header = ({ additionalListParams }: Props) => {
  return (
    <div>
      <PageHeader
        actionButtons={
          <div className="d-flex align-items-center gap-1">
            <IconButton icon={MdiUploadFile} variant="blackT" />
            <IconButton icon={MdiDownloadFile} variant="blackT" />
            <span className="line-divider lineDividerMargin" />
            <IconButton icon={MdiVisibilityOff} variant="blackT" />
            <IconButton icon={MdiAreaChart} variant="blackT" />
          </div>
        }
        additionalListParams={additionalListParams}
        searchInput={{
          label: "Szukaj wśród faktur proforma",
          tags: [
            {
              name: "issueDateFrom",
              label: "data wystawienia (od)",
              value: "2023-08-01",
            },
            {
              name: "issueDateTo",
              label: "data wystawienia (do)",
              value: "2023-08-04",
            },
          ],
        }}
        tabs={{
          list: getTabs(),
          routesRoot: `finances/${proformas.url}`,
          urlSpan: "list",
        }}
        viewLabel="PROFORMAS"
      />
    </div>
  );
};
