import { ToggleHookState } from "hooks";
import styles from "pages/tradingDocuments/shared/TradingDocumentsLists.module.css";
import { FinancesAmountSummary } from "api/trading-documents/models";
import { Button as DesignSystemButton } from "components/miloDesignSystem/atoms/button";
import { MdiArrowForward } from "components/miloDesignSystem/atoms/icons/MdiArrowForward";
import { IconButton } from "components/miloDesignSystem/atoms/iconButton";
import { MdiPrintStatus } from "components/miloDesignSystem/atoms/icons/MdiPrintStatus";
import { MdiPrintConnect } from "components/miloDesignSystem/atoms/icons/MdiPrintConnect";

interface Props {
  amountSummary: FinancesAmountSummary | null;
  amountSummaryModal: ToggleHookState;
  currentlyPrintedReceiptsModal: ToggleHookState;
  isLoading: boolean;
  lastPrintedReceiptsModal: ToggleHookState;
}

export const BottomBarOptions = ({
  amountSummary,
  amountSummaryModal,
  currentlyPrintedReceiptsModal,
  isLoading,
  lastPrintedReceiptsModal,
}: Props) => {
  return (
    <>
      <div>
        <div className="d-flex align-items-center justify-content-end gap-2">
          <div className="d-flex align-items-center justify-content-end gap-1">
            <div className={styles.labelText}>netto:</div>
            <strong className="body-16-800">
              {amountSummary
                ? Number(amountSummary.totalAmountWithoutTax.toFixed(2)).toLocaleString("de-DE", {
                    style: "decimal",
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })
                : isLoading
                ? "..."
                : "brak danych"}{" "}
              PLN
            </strong>
          </div>
          <div className="d-flex align-items-center justify-content-end gap-1">
            <div className={styles.labelText}>VAT:</div>
            <strong className="body-16-800">
              {amountSummary
                ? Number(amountSummary.totalTax.toFixed(2)).toLocaleString("de-DE", {
                    style: "decimal",
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })
                : isLoading
                ? "..."
                : "brak danych"}{" "}
              PLN
            </strong>
          </div>
          <DesignSystemButton
            className="text-uppercase"
            disabled={isLoading}
            endIcon={MdiArrowForward}
            onClick={amountSummaryModal.open}
            size="small"
            variant="gray"
          >
            Szczegóły
          </DesignSystemButton>
        </div>
      </div>
      <div className="d-flex align-items-center gap-2">
        <span className="line-divider lineDividerMargin" />
        <IconButton
          icon={MdiPrintConnect}
          onClick={lastPrintedReceiptsModal.open}
          title="Ostatnio wydrukowane"
          variant="blackT"
        />
        <span className="line-divider lineDividerMargin" />
        <IconButton
          icon={MdiPrintStatus}
          onClick={currentlyPrintedReceiptsModal.open}
          title="Paragony w druku"
          variant="blackT"
        />
      </div>
    </>
  );
};
