import { PackageStockStatus, UnloadingGroupPackage } from "api/wms/models";
import { Tag } from "components/miloDesignSystem/atoms/tag";
import { useCreateTableColumns } from "utilities/tableColumnsUtilities/createTableColumns/createTableColumns";
import { unloadingPackageGroupStatusDict } from "../ordersSection/lineItemsDetailsModal/LineItemsDetailsModal";
import { Typography } from "components/miloDesignSystem/atoms/typography";
import { dateFns } from "utilities";
import { parseLocation } from "utilities/parseLocation";

export const useUnloadingWorkspaceColumns = () =>
  useCreateTableColumns<UnloadingGroupPackage>(({ columnHelper }) => {
    return [
      columnHelper.text(row => (Boolean(row.name.length) ? row.name : "---"), {
        header: "nazwa paczki",
        size: 570,
      }),
      columnHelper.text(row => parseLocation(row.location).name, {
        header: "lokalizacja",
        size: 100,
        typographyProps: {
          fontSize: "14",
          fontWeight: "700",
          color: "success500",
        },
      }),
      columnHelper.accessor(row => row.stockStatus, {
        header: "status",
        size: 120,
        cell: info => {
          const status: PackageStockStatus = info.getValue();
          return (
            <Tag
              label={unloadingPackageGroupStatusDict[status].label}
              variant={unloadingPackageGroupStatusDict[status].variant}
            />
          );
        },
      }),
      columnHelper.accessor(row => row.addedToStockAt, {
        id: "when",
        header: () => (
          <Typography
            className="text-right w-100"
            color="neutralBlack48"
            fontSize="12"
            fontWeight="400"
          >
            kiedy?
          </Typography>
        ),
        size: 110,
        cell: info => {
          return (
            <Typography className="text-right w-100" fontSize="12" fontWeight="700">
              {info.getValue()
                ? dateFns.formatRelative(new Date(info.getValue()), "dd.MM.yyyy, H:mm")
                : "---"}
            </Typography>
          );
        },
      }),
    ];
  });
