import { tradingDocumentsActions } from "api/trading-documents/actions";
import { useTradingDocument } from "api/trading-documents/hooks";
import { BulkSalesInvoiceConfirmPreview } from "api/trading-documents/models";
import { proformasActions } from "api/trading-documents/proforma/actions";
import { Button } from "components/miloDesignSystem/atoms/button";
import { IconButton } from "components/miloDesignSystem/atoms/iconButton";
import { MdiAdd } from "components/miloDesignSystem/atoms/icons/MdiAdd";
import { MdiDownloadPdf } from "components/miloDesignSystem/atoms/icons/MdiDownloadPdf";
import { MdiKeyboardTab } from "components/miloDesignSystem/atoms/icons/MdiKeyboardTab";
import { Tooltip } from "components/miloDesignSystem/atoms/tooltip";
import { RightPanelHeader } from "components/utils/drawer";
import { useQuery, useStateModal } from "hooks";
import { ReplyModal } from "pages/tradingDocuments/shared/components/actionToolbar/ReplyModal";
import { ConfirmCheckbox } from "pages/tradingDocuments/shared/components/confirmCheckbox/ConfirmCheckbox";
import { ConfirmTradingDocument } from "pages/tradingDocuments/shared/components/rightPanel/ConfirmTradingDocument";
import { MoreInfoButton } from "pages/tradingDocuments/shared/components/rightPanel/moreInfoButton/MoreInfoButton";
import { cx } from "utilities";

interface Props {
  close: () => void;
  isScrolledOver: boolean;
}

export const PanelHeader = ({ close, isScrolledOver }: Props) => {
  const { query } = useQuery();
  const { panelId } = query;
  const { data: proforma } = useTradingDocument({ id: panelId });
  const replyModal = useStateModal<BulkSalesInvoiceConfirmPreview>();
  const handleProformaPdfDownload = tradingDocumentsActions.useInvoicePdfDownload();
  const createSalesInvoiceFromProformaMutation = proformasActions.useCreateSalesInvoiceFromProforma();

  if (!proforma) return null;

  return (
    <>
      <RightPanelHeader
        className={cx({
          slidingPanelHeaderShadow: isScrolledOver,
          panelHeaderDuringSlide: isScrolledOver,
        })}
      >
        {isScrolledOver ? (
          <div className="slidingPanelHeader">
            <div className="slidingPanelHeaderContent d-flex align-items-center gap-2">
              <ConfirmCheckbox tradingDocument={proforma} replyModal={replyModal} />
              <div>Proforma&nbsp;{proforma.signature}</div>
            </div>
          </div>
        ) : (
          <ConfirmTradingDocument tradingDocument={proforma} />
        )}
        <div className="d-flex align-items-center justify-content-end gap-1">
          {proforma.status === "CONFIRMED" && (
            <Button
              className="text-uppercase"
              isLoading={createSalesInvoiceFromProformaMutation.isLoading}
              onClick={() => createSalesInvoiceFromProformaMutation.mutate(proforma.id)}
              size="small"
              startIcon={MdiAdd}
              variant="gray"
            >
              Przekształć do faktury sprzedażowej
            </Button>
          )}
          <Tooltip title="Pobierz PDF z danymi proformy">
            <IconButton
              icon={<MdiDownloadPdf size="18" />}
              onClick={() => handleProformaPdfDownload(proforma)}
              variant="blackT"
            />
          </Tooltip>
          <MoreInfoButton close={close} invoice={proforma} panelId={panelId} />
          <span className="divider line-divider" />
          <IconButton icon={MdiKeyboardTab} onClick={close} variant="blackT" />
        </div>
      </RightPanelHeader>
      {replyModal.isOpen && (
        <ReplyModal bulkInvoiceConfirmation={replyModal.state} close={replyModal.close} />
      )}
    </>
  );
};
